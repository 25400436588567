






























import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { FETCH_LISTS_OF_ASSETS, GET_ITEM } from '@/modules/listOfAssets/store';
import { ApiResponse } from '@/components/types';
import { ListOfAssets } from '@/modules/listOfAssets/types';
import { routeToLocation } from '@/router';

const FinanceAccountTab = () => import('../components/details/tabs/FinanceAccountTab.vue');
const AssetsTab = () => import('../components/details/tabs/AssetsTab.vue');
const AccountingTab = () => import('../components/details/tabs/AccountingTab.vue');

const ListOfAssets = namespace('listOfAssets');

@Component({
  components: { FinanceAccountTab, AssetsTab, AccountingTab }
})
export default class ListOfAssetDetails extends Vue {
  @ListOfAssets.Action(FETCH_LISTS_OF_ASSETS) fetchList!: () => Promise<ApiResponse>;
  @ListOfAssets.Getter(GET_ITEM) getItem!: (listOfAssetsId: string) => ListOfAssets | undefined;

  tab: string = 'tab-finance-account';

  tabs: Array<{ label: string, icon: string, name: string, component: string, condition?: boolean }> = [
    {
      label: 'label.financeAccount.title',
      icon: 'monetization_on',
      name: 'tab-finance-account',
      component: 'finance-account-tab'
    },
    { label: 'common.assets', icon: 'account_balance', name: 'tab-assets', component: 'assets-tab' },
    {
      label: 'common.accounting',
      icon: 'description',
      name: 'tab-accounting',
      component: 'accounting-tab',
      condition: this.$vuetify.breakpoint.mdAndUp
    },
  ].filter(tab => tab.condition === undefined ? true : tab.condition);

  get listOfAssets() {
    return this.getItem(this.$route.params['id']);
  }

  get active(): string {
    return this.$route.query['active'] as string || 'tab-finance-account';
  }

  set active(active: string) {
    this.$router.push(routeToLocation({ ...this.$route, query: { ...this.$route.query, active } })).catch(() => {});
  }

  @Watch('active', { immediate: true })
  onActive(active: string) {
    this.fetchList();

    setTimeout(() => this.tab = active, 100);
  }

  @Watch('tab', { immediate: true })
  onTab(tab: string) {
    if (tab === this.active) return;

    setTimeout(() => this.active = tab, 0);
  }
}
